import { GetChatRoomsQuery } from '@/gql/graphql';
import { useEffect, useRef, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';

interface ChatRoomListProps {
  loading: boolean;
  chatRoomsData: GetChatRoomsQuery | undefined;
  activeChatRoomId: string | null;
  handleClickConversation: (id: string) => void;
  setIsChatSelectionVisible: (visible: boolean) => void;
  handleDeleteChatRoom: (id: string) => void;
}

const ChatRoomList: React.FC<ChatRoomListProps> = ({
  loading,
  chatRoomsData,
  activeChatRoomId,
  handleClickConversation,
  setIsChatSelectionVisible,
  handleDeleteChatRoom
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const computedStyle = window.getComputedStyle(containerRef.current);
        const paddingLeft = parseFloat(computedStyle.paddingLeft);
        const paddingRight = parseFloat(computedStyle.paddingRight);

        const availableWidth = containerRef.current.clientWidth - paddingLeft - paddingRight - 32;
        setContainerWidth(availableWidth);
      }
    };

    updateWidth();
    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const truncateText = (text: string, maxWidth: number, element: HTMLElement) => {
    if (!text) return '';

    const span = document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.position = 'absolute';
    span.style.whiteSpace = 'nowrap';
    span.style.font = window.getComputedStyle(element).font;
    document.body.appendChild(span);

    span.textContent = text;
    const textWidth = span.offsetWidth;

    if (textWidth <= maxWidth) {
      document.body.removeChild(span);
      return text;
    }

    let left = 0;
    let right = text.length;
    let result = '';

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const truncated = text.slice(0, mid) + '...';
      span.textContent = truncated;
      const width = span.offsetWidth;

      if (width <= maxWidth) {
        result = truncated;
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }

    document.body.removeChild(span);
    return result;
  };

  return (
    <div ref={containerRef} className='scrollbar-hide h-[calc(100vh-10rem)] flex-1 space-y-4 overflow-y-auto'>
      {loading ? (
        <div className='text-center'>Loading...</div>
      ) : (
        chatRoomsData?.findManyChatRooms.map((chatRoom) => (
          <div
            key={chatRoom.id}
            className={`group cursor-pointer rounded border p-4 hover:bg-gray-100 ${
              chatRoom.id === activeChatRoomId ? 'bg-gray-200' : ''
            } relative`}
            onClick={() => {
              handleClickConversation(chatRoom.id);
              setIsChatSelectionVisible(false);
            }}>
            {/* Delete Button */}
            <button
              className="absolute right-2 top-2 p-1.5 rounded-lg text-gray-400 hover:text-red-600 hover:bg-red-50 transition-all duration-200 opacity-0 group-hover:opacity-100"
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the parent's onClick
                if (window.confirm('Are you sure you want to delete this chat room?')) {
                  handleDeleteChatRoom(chatRoom.id);
                }
              }}
            >
              <TrashIcon className="h-4 w-4" />
            </button>
            <div className='flex h-[72px] flex-col justify-between'>
              <div className='flex w-full flex-col space-y-2'>
                <span
                  className='line-clamp-1 font-bold'
                  ref={(el) => {
                    if (el && containerWidth > 0) {
                      el.textContent = truncateText(chatRoom.name, containerWidth, el);
                    }
                  }}>
                  {chatRoom.name}
                </span>
                <div 
                  className='line-clamp-2 min-h-[40px] font-myriad font-medium text-gray-600'
                  ref={(el) => {
                    if (el && containerWidth > 0) {
                      const text = chatRoom.lastMessage?.text || 'No messages';
                      el.textContent = truncateText(text, containerWidth * 2, el);
                    }
                  }}>
                  {chatRoom.lastMessage?.text || 'No messages'}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ChatRoomList;
