import { Navigate, useRoutes } from 'react-router-dom';
import Header from '../components/Header';
import SignInPage from '../pages/SignInPage';
import UserChatPage from '../pages/user_chat/UserChatPage';
import FilePage from '../pages/file/FilePage';
import SettingsPage from '../pages/settings/SettingsPage';
import UserSettingProfile from '../pages/settings/setting_profile/SettingProfile';
import AgentsPage from '../pages/agent/AgentsPage';
import { useKeycloakAuth } from '@/context/KeycloakAuthProvider';
import { useEffect, useState } from 'react';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isSignedIn } = useKeycloakAuth();

  if (!isSignedIn) {
    return <Navigate to='/signin' replace />;
  }

  return <>{children}</>;
};

const AdminRoute = ({ children }: { children: React.ReactNode }) => {
  const { isSignedIn, isAdmin } = useKeycloakAuth();
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAdmin = async () => {
      if (isSignedIn) {
        const adminStatus = await isAdmin();
        setIsAdminUser(adminStatus);
      }
      setIsLoading(false);
    };
    checkAdmin();
  }, [isSignedIn, isAdmin]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a proper loading component
  }

  if (!isSignedIn || !isAdminUser) {
    return <Navigate to='/chat' replace />;
  }

  return <>{children}</>;
};

const Router = () => {
  const routes = [
    {
      path: '/signin',
      element: <SignInPage />
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Header />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'chat',
          element: <UserChatPage />
        },
        {
          path: 'file',
          element: (
            <AdminRoute>
              <FilePage />
            </AdminRoute>
          )
        },
        {
          path: 'agents',
          element: (
            <AdminRoute>
              <AgentsPage />
            </AdminRoute>
          )
        },
        {
          path: 'settings',
          element: <SettingsPage />,
          children: [
            {
              path: 'profile',
              element: <UserSettingProfile />
            }
          ]
        }
      ]
    }
  ];

  return useRoutes(routes);
};

export default Router;
