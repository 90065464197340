import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import { currentTimestampString } from '@/utils/friendly_timestamp';
import { generate_uuid } from '@/utils/uuid_generator';
import { generateUniqueId } from '@/utils/file_util';
import { useKeycloakAuth } from './KeycloakAuthProvider';
import { Message, MessageRole, DocumentStatus, Document, Agent, GetMessagesByChatRoomQuery, GetDocumentsQuery, GetFoldersQuery, Folder } from '@/gql/graphql';
import { ApolloQueryResult } from '@apollo/client';
import { createFolderTree } from '@/utils/folder_utils';

const createEmptyMessage = (): Message => ({
  id: '',
  text: '',
  role: MessageRole.Assistant,
  userId: '',
  chatRoomId: '',
  createdAt: currentTimestampString(),
  updatedAt: currentTimestampString()
});

interface DocumentCreation {
  new_file_id: string;
  filename: string;
  original_file_id: string;
}

export interface FileStatus {
  document: Document;
  isUploading: boolean;
  error?: string;
  folderId?: string;
  progress: number;
}

interface IChatContext {
  // Chat functionality
  sendMessage: (messageText: string, chatHistory: Message[], agent?: Agent | null) => void;
  isWaitingForResponse: ReturnType<typeof useRef<boolean>>;
  socket: Socket | null;
  streamingMessage: Message | null;
  temporalUserMessage: Message | null;
  activeChatRoomId: string | null;
  setActiveChatRoomId: (chatRoomId: string) => void;
  isConnecting: boolean;
  error: Error | null;
  // Document functionality
  createdDocuments: Record<string, DocumentCreation>;
  uploadFolderTree: (rootFolder: Folder) => void;
  deleteDocument: (documentId: string) => Promise<boolean>;
  // File status management
  fileStatuses: Record<string, FileStatus>;
  setFileStatus: (fileId: string, status: Partial<FileStatus>) => void;
  clearFileStatuses: () => void;
  messageRefetchNotified: boolean;
  setMessageRefetchNotified: (notified: boolean) => void;
}

const ChatContext = createContext<IChatContext>({
  sendMessage: (messageText: string, chatHistory: Message[], agent?: Agent | null) => {
    console.log(messageText, chatHistory, agent);
  },
  isWaitingForResponse: { current: false },
  socket: null,
  streamingMessage: null,
  temporalUserMessage: null,
  activeChatRoomId: null,
  setActiveChatRoomId: (chatRoomId: string) => {
    console.log(chatRoomId);
  },
  isConnecting: false,
  error: null,
  createdDocuments: {},
  uploadFolderTree: () => {},
  deleteDocument: async () => false,
  fileStatuses: {},
  setFileStatus: () => {},
  clearFileStatuses: () => {},
  messageRefetchNotified: false,
  setMessageRefetchNotified: () => {}
});

interface IChatProviderProps {
  children: ReactNode;
}

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }: IChatProviderProps) => {
  const chat = useProvideChat();
  return <ChatContext.Provider value={chat}>{children}</ChatContext.Provider>;
};

const useProvideChat = () => {
  const { user } = useKeycloakAuth();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [streamingMessage, setStreamingMessage] = useState<Message | null>(null);
  const [temporalUserMessage, setTemporalUserMessage] = useState<Message | null>(null);
  const [activeChatRoomId, setActiveChatRoomId] = useState<string | null>(null);

  useEffect(() => {
    // Clean up temporal message when changing chat rooms
    setTemporalUserMessage(null);
    setStreamingMessage(null);
    setMessageRefetchNotified(false);
  }, [activeChatRoomId]);

  const [streamMessageID, setStreamMessageID] = useState<string>('');
  const [createdDocuments, setCreatedDocuments] = useState<Record<string, DocumentCreation>>({});
  const [fileStatuses, setFileStatuses] = useState<Record<string, FileStatus>>({});
  const [messageRefetchNotified, setMessageRefetchNotified] = useState<boolean>(false);

  // Refs for managing streaming state
  const streamMessageBufferRef = useRef<Message>(createEmptyMessage());
  const streamMessageProgressingRef = useRef<boolean>(false);
  const isWaitingForResponse = useRef<boolean>(false);
  const streamIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Reset streaming state if messageRefetchNotified is false: this is due to:
    // 1. Refetching messages from the server
    // 2. Switching chat rooms
    if (!messageRefetchNotified) {
      setStreamingMessage(null);
      setTemporalUserMessage(null);
    }
  }, [messageRefetchNotified]);

  useEffect(() => {
    if (!user?.idToken) {
      console.log('[Socket] No user token available, skipping connection');
      return;
    }

    if (socket?.connected) {
      console.log('[Socket] Already connected, skipping new connection');
      return;
    }

    setIsConnecting(true);
    setError(null);

    // Create socket connection to root namespace
    const verbaSocket = io('/', {
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
      autoConnect: true,
      auth: {
        token: user?.accessToken
      }
    });

    // Event handlers with prefixed events
    const handlers = {
      connect: () => {
        setIsConnecting(false);
        console.log('[Socket] Connected successfully:', verbaSocket.id);
      },

      connect_error: (error: Error) => {
        setError(error);
        setIsConnecting(false);
        console.error('[Socket] Connection error:', error.message);
      },

      // Update event names with 'chat_' prefix
      chat_error: (error: string) => {
        console.error('[Socket] Chat error:', error);
        isWaitingForResponse.current = false;
      },

      chat_message: (data: { ok: boolean; message: string; chat_message: Message }) => {
        console.log('[Socket] Chat message received:', data);
        if (data.ok && data.chat_message) {
          const newMessage: Message = {
            id: data.chat_message.id,
            text: data.chat_message.text,
            role: data.chat_message.role,
            userId: data.chat_message.userId,
            chatRoomId: data.chat_message.chatRoomId,
            createdAt: data.chat_message.createdAt,
            updatedAt: data.chat_message.updatedAt
          };
          console.log('[Socket] Message processed:', newMessage);
        } else if (!data.ok) {
          console.error('[Socket] Message error:', data.message);
        }
        isWaitingForResponse.current = false;
      },

      chat_message_stream_start: (data: { ok: boolean; message: string; chat_message: Message }, ack: () => void) => {
        console.log('[Socket] Stream started:', data);
        if (data.ok) {
          streamMessageProgressingRef.current = true;
          setStreamingMessage(data.chat_message);
          if (ack) ack();
        } else {
          console.error('[Socket] Stream start failed:', data.message);
        }
      },

      chat_message_stream: (data: { ok: boolean; message: string; chat_message: Message }) => {
        if (data.ok && streamMessageProgressingRef.current) {
          const newText = (streamMessageBufferRef.current.text || '') + (data.chat_message.text || '');
          streamMessageBufferRef.current.text = newText;

          setStreamingMessage((prevMessage) => ({
            ...(prevMessage || createEmptyMessage()),
            text: newText,
            id: data.chat_message.id,
            role: MessageRole.Assistant,
            chatRoomId: activeChatRoomId || '',
            userId: data.chat_message.userId || '',
            createdAt: data.chat_message.createdAt || currentTimestampString(),
            updatedAt: data.chat_message.updatedAt || currentTimestampString()
          }));
        }
      },

      chat_message_stream_end: async (data: { ok: boolean; message: string; chat_message: Message }) => {
        console.log('[Socket] Stream ended:', data);
        if (data.ok) {
          const finalMessage = {
            ...streamMessageBufferRef.current,
            id: data.chat_message.id,
            text: streamMessageBufferRef.current.text,
            role: MessageRole.Assistant,
            userId: data.chat_message.userId,
            chatRoomId: data.chat_message.chatRoomId,
            createdAt: data.chat_message.createdAt,
            updatedAt: data.chat_message.updatedAt
          };

          // Set the final message one last time before clearing
          setStreamingMessage(finalMessage);
        } else {
          console.error('[Socket] Stream end error:', data.message);
          setStreamingMessage(null); // Clear streaming message on error
        }

        isWaitingForResponse.current = false;
        console.log('[Socket] Reset isWaitingForResponse to:', isWaitingForResponse.current);
        cleanupStreamingState();

        // Set messageRefetchNotified to true when streaming is complete or error
        setMessageRefetchNotified(true);
      },

      // Document events
      document_created: (data: DocumentCreation) => {
        console.log('[Socket] Document created:', data);
        setCreatedDocuments((prev) => ({
          ...prev,
          [data.original_file_id]: data
        }));
      },

      document_status_report: (data: { fileID: string; status: DocumentStatus; progress: number; message: string }) => {
        console.log('[Socket] Document status report:', data);
        const progress = Math.round(Math.min(100, Math.max(0, data.progress)) / 5) * 5; // Round to nearest 5%

        setFileStatuses((prev) => {
          const currentStatus = prev[data.fileID];
          if (!currentStatus) return prev;

          // Update the existing file status with new progress and status
          return {
            ...prev,
            [data.fileID]: {
              ...currentStatus,
              document: {
                ...currentStatus.document,
                status: data.status
              },
              progress
            }
          };
        });
      },

      document_processed: async (data: { fileID: string; status: 'success' | 'error' }) => {
        console.log('[Socket] Document processed:', data);
        if (data.status === 'success') {
          // Remove from file statuses since it's now processed
          setFileStatuses((prev) => {
            const newStatuses = { ...prev };
            delete newStatuses[data.fileID];
            return newStatuses;
          });
        }
      },

      document_error: (error: { fileID: string; message: string }) => {
        console.error('[Socket] Document error:', error);
        setError(new Error(error.message));
      },

      folder_created: (data: { path: string }) => {
        console.log('[Socket] Folder created:', data.path);
      },

      folder_error: (error: { path: string; message: string }) => {
        console.error('[Socket] Folder error:', error);
        setError(new Error(error.message));
      }
    };

    // Attach handlers
    Object.entries(handlers).forEach(([event, handler]) => {
      verbaSocket.on(event, handler);
    });

    setSocket(verbaSocket);

    return () => {
      if (verbaSocket.connected) {
        console.log('[Socket] Cleaning up connection');
        // Remove all handlers
        Object.keys(handlers).forEach((event) => {
          verbaSocket.off(event);
        });
        verbaSocket.disconnect();
        setSocket(null);
      }
    };
  }, [user?.idToken, activeChatRoomId]);

  const cleanupStreamingState = useCallback(() => {
    if (streamIntervalRef.current) {
      clearInterval(streamIntervalRef.current);
      streamIntervalRef.current = null;
    }
    streamMessageProgressingRef.current = false;
    streamMessageBufferRef.current = createEmptyMessage();
  }, []);

  const sendMessage = useCallback(
    (messageText: string, chatHistory: Message[], agent?: Agent | null) => {
      if (!socket || !activeChatRoomId) {
        console.error('[Socket] Cannot send message: No socket connection or active chat room');
        return;
      }

      try {
        // Create a temporal user message
        const temporalMessage = {
          id: generate_uuid(),
          text: messageText,
          role: MessageRole.User,
          userId: user?.id || '',
          chatRoomId: activeChatRoomId,
          createdAt: currentTimestampString(),
          updatedAt: currentTimestampString()
        };
        setTemporalUserMessage(temporalMessage);

        // Set waiting flag before emitting
        isWaitingForResponse.current = true;

        // Emit the chat message with all required data
        socket.emit('chat_message', {
          id: temporalMessage.id,
          text: messageText,
          chatHistory: chatHistory,
          chatRoomId: activeChatRoomId,
          agentId: agent?.id || null,
          userId: user?.id || ''
        });
      } catch (error) {
        console.error('[Socket] Error sending message:', error);
        isWaitingForResponse.current = false;
      }
    },
    [socket, activeChatRoomId, user?.id]
  );

  const setFileStatus = useCallback((fileId: string, status: Partial<FileStatus>) => {
    setFileStatuses((prev) => ({
      ...prev,
      [fileId]: {
        ...prev[fileId],
        ...status,
        progress: status.progress ?? prev[fileId]?.progress ?? 0
      }
    }));
  }, []);

  const clearFileStatuses = useCallback(() => {
    setFileStatuses({});
  }, []);

  const uploadFolderTree = useCallback(
    async (rootFolder: Folder) => {
      if (!socket?.connected) {
        console.error('Cannot upload - Socket not connected');
        return;
      }

      try {
        const metadataTree = createFolderTree(rootFolder);

        // Step 1: Create folder structure using WebSocket (metadata only)
        const folderStructureResult = await new Promise<{
          success: boolean;
          rootFolderId: string;
          error?: string;
        }>((resolve, reject) => {
          const timeout = setTimeout(() => {
            socket.off('folder_structure_created');
            reject(new Error('Folder structure creation timed out'));
          }, 30000);

          socket.once('folder_structure_created', (response) => {
            clearTimeout(timeout);
            if (response.success) {
              resolve({
                success: response.success,
                rootFolderId: response.root_folder_id
              });
            } else {
              reject(new Error(response.error || 'Failed to create folder structure'));
            }
          });

          socket.emit('document_create_folder_structure', {
            folder_structure: metadataTree
          });
        });

        if (!folderStructureResult.success) {
          throw new Error(folderStructureResult.error || 'Failed to create folder structure');
        }

        // Step 2: Process files after folder structure is created
        const processNode = async (folder: Folder) => {
          // Recursively process any subfolders first
          if (folder.subFolders && folder.subFolders.length > 0) {
            for (const subFolder of folder.subFolders) {
              await processNode(subFolder);
            }
          }

          // Now process each document in this folder
          if (folder.documents && folder.documents.length > 0) {
            for (const doc of folder.documents) {
              try {
                // Here is where we handle document text chunking and upload
                const fileData = doc.text ?? '';
                const fileSizeBytes = new TextEncoder().encode(fileData).length;

                // Basic size limit check (adjust threshold as needed)
                if (fileSizeBytes > 10 * 1024 * 1024) {
                  throw new Error(`Document "${doc.title}" exceeds maximum size limit of 10MB`);
                }

                // Assign a temporary file ID
                const fileID = generateUniqueId();
                const chunkSize = 4 * 1024; // 4KB chunks
                const chunks = Math.ceil(fileData.length / chunkSize);

                // Update UI state before sending data
                setFileStatuses((prev) => ({
                  ...prev,
                  [fileID]: {
                    document: {
                      id: fileID,
                      title: doc.title,
                      extension: doc.extension,
                      fileSize: doc.fileSize,
                      status: DocumentStatus.Pending,
                      folderId: folder.id,
                      createdAt: doc.createdAt ?? new Date().toISOString(),
                      updatedAt: doc.updatedAt ?? new Date().toISOString(),
                      userId: user?.id || ''
                    },
                    isUploading: true,
                    folderId: folder.id,
                    progress: 0
                  }
                }));

                // Emit initial batch metadata
                await new Promise<void>((resolve) => {
                  socket.emit(
                    'document_batch',
                    {
                      fileID,
                      file_config: {
                        fileID,
                        title: doc.title,
                        extension: doc.extension,
                        content: '', // Will be populated by chunks
                        source: doc.source || 'upload',
                        isURL: false,
                        overwrite: false,
                        folder_name: folder.name,
                        folder_id: folder.id,
                        file_size: doc.fileSize,
                        status: DocumentStatus.Pending
                      },
                      total: chunks
                    },
                    () => resolve()
                  );
                });

                // Send data in chunks
                for (let i = 0; i < chunks; i++) {
                  const chunk = fileData.slice(i * chunkSize, (i + 1) * chunkSize);
                  await new Promise<void>((resolve) => {
                    socket.emit(
                      'document_batch',
                      {
                        fileID,
                        chunk,
                        isLastChunk: i === chunks - 1,
                        total: chunks,
                        order: i
                      },
                      () => resolve()
                    );
                  });

                  // Update progress (rounded to the nearest 5%)
                  const rawProgress = ((i + 1) / chunks) * 100;
                  const uploadProgress = Math.round(rawProgress / 5) * 5;

                  setFileStatuses((prev) => ({
                    ...prev,
                    [fileID]: {
                      ...prev[fileID],
                      progress: uploadProgress
                    }
                  }));
                }
              } catch (error) {
                console.error('Error processing document:', error);
                throw error;
              }
            }
          }
        };

        // Start processing from root folder
        await processNode(rootFolder);
      } catch (error) {
        console.error('Error during folder tree upload:', error);
        throw error;
      }
    },
    [socket, setFileStatus]
  );

  const deleteDocument = useCallback(
    async (documentId: string) => {
      try {
        const response = await fetch('/api/delete_document', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`
          },
          body: JSON.stringify({ uuid: documentId })
        });

        if (!response.ok) {
          throw new Error(`Failed to delete document: ${response.statusText}`);
        }

        return true;
      } catch (error) {
        console.error('Error deleting document:', error);
        return false;
      }
    },
    [user?.accessToken]
  );

  return {
    sendMessage,
    isWaitingForResponse,
    socket,
    streamingMessage,
    temporalUserMessage,
    activeChatRoomId,
    setActiveChatRoomId,
    isConnecting,
    error,
    createdDocuments,
    uploadFolderTree,
    deleteDocument,
    fileStatuses,
    setFileStatus,
    clearFileStatuses,
    messageRefetchNotified,
    setMessageRefetchNotified
  };
};
