import React, { useEffect, useState } from 'react';

interface ModalProps {
  visible: boolean;
  title: React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
  onOk: () => void;
  confirmLoading?: boolean;
  destroyOnClose?: boolean;
  afterClose?: () => void;
  width?: string | number;
}

const Modal: React.FC<ModalProps> = ({ 
  visible, 
  title, 
  children, 
  onClose, 
  onOk, 
  confirmLoading, 
  destroyOnClose, 
  afterClose, 
  width = '90vw' 
}) => {
  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  const getModalWidth = () => {
    if (typeof width === 'number') return `${width}px`;
    if (typeof width === 'string') return width;
    return '90vw';
  };

  const maxWidth = typeof width === 'number' ? `${width}px` : '600px';

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center transition-all duration-300 ${
        isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
      aria-modal="true"
      role="dialog"
    >
      {/* Backdrop */}
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300" 
        onClick={onClose}
      />

      {/* Modal Container */}
      <div
        className={`relative w-full mx-4 transform transition-all duration-300 ${
          isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
        }`}
        style={{ 
          maxWidth: maxWidth,
          width: getModalWidth()
        }}
      >
        {/* Modal Content */}
        <div className="bg-white rounded-xl shadow-xl overflow-hidden">
          {/* Header */}
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-100">
            <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
            <button 
              onClick={onClose}
              className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
            >
              <svg className="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Body */}
          <div className="px-6 py-4 max-h-[calc(80vh-8rem)] overflow-y-auto">
            {children}
          </div>

          {/* Footer */}
          <div className="flex justify-end gap-3 px-6 py-4 border-t border-gray-100">
            <button 
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-uehPrimary transition-all duration-200"
            >
              Cancel
            </button>
            <button
              onClick={onOk}
              disabled={confirmLoading}
              className="px-4 py-2 text-sm font-medium text-white bg-uehPrimary rounded-lg hover:bg-uehPrimary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-uehPrimary disabled:bg-gray-300 disabled:cursor-not-allowed transition-all duration-200"
            >
              {confirmLoading ? 'Loading...' : 'OK'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
