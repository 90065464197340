import { createContext, ReactNode, useCallback, useContext } from 'react';
import { useKeycloakAuth } from './KeycloakAuthProvider';
import { ChunkPayload, ChunksPayload, DatacountResponse, DocumentFilter, FolderNamesResponse, FoldersResponse, MetadataPayload } from '@/types/types';
import { Agent, Folder } from '@/gql/graphql';

interface ApiContextType {
  fetchWithAuth: <T>(endpoint: string, options?: RequestInit) => Promise<T | null>;
  fetchDatacount: (documentFilter: DocumentFilter[]) => Promise<DatacountResponse | null>;
  fetchFolderNames: () => Promise<FolderNamesResponse | null>;
  fetchFolders: () => Promise<FoldersResponse | null>;
  deleteFolder: (folderId: string) => Promise<{ status: number; folder?: Folder; status_msg?: string }>;
  fetch_chunks: (uuid: string | null, page: number, pageSize: number) => Promise<ChunksPayload | null>;
  fetch_chunk: (uuid: string | null) => Promise<ChunkPayload | null>;
  deleteDocument: (uuid: string) => Promise<boolean>;
  createAgent: (
    name: string,
    welcomeMessage: string,
    systemMessage: string,
    errorMessage: string,
    languageId: string,
    folderIds: string[]
  ) => Promise<{ status: number; agent?: Agent; status_msg?: string }>;
  updateAgent: (agentId: string, agent: Agent) => Promise<{ status: number; agent?: Agent; status_msg?: string }>;
  deleteAgent: (agentId: string) => Promise<{ status: number; agent?: Agent; status_msg?: string }>;
}

const ApiContext = createContext<ApiContextType | undefined>(undefined);

export function ApiProvider({ children }: { children: ReactNode }) {
  const { user } = useKeycloakAuth();

  const checkUrl = useCallback(async (url: string): Promise<boolean> => {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      console.error(`Failed to fetch from ${url}:`, error);
      return false;
    }
  }, []);

  const fetchWithAuth = useCallback(
    async <T,>(endpoint: string, options: RequestInit = {}): Promise<T | null> => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          ...(user?.accessToken && { Authorization: `Bearer ${user.accessToken}` }),
          ...options.headers
        };

        const response = await fetch(`${endpoint}`, {
          ...options,
          headers
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
      } catch (error) {
        console.error(`Failed to fetch from ${endpoint}:`, error);
        return null;
      }
    },
    [user?.accessToken]
  );

  const fetchDatacount = useCallback(
    async (documentFilter: DocumentFilter[]): Promise<DatacountResponse | null> => {
      return fetchWithAuth('/api/get_datacount', {
        method: 'POST',
        body: JSON.stringify({ documentFilter })
      });
    },
    [fetchWithAuth]
  );

  const fetchFolderNames = useCallback(async (): Promise<FolderNamesResponse | null> => {
    return fetchWithAuth('/api/get_folder_names', {
      method: 'POST'
    });
  }, [fetchWithAuth]);

  const fetchFolders = useCallback(async (): Promise<FoldersResponse | null> => {
    return fetchWithAuth('/api/get_folders', {
      method: 'POST'
    });
  }, [fetchWithAuth]);

  const deleteFolder = useCallback(
    async (folderId: string): Promise<{ status: number; folder?: Folder; status_msg?: string }> => {
      const result: any = await fetchWithAuth('/api/delete_folder', {
        method: 'POST',
        body: JSON.stringify({ folder_id: folderId })
      });

      return {
        status: result?.status || 500,
        status_msg: result?.status_msg || 'Failed to delete folder'
      };
    },
    [fetchWithAuth]
  );

  const fetch_chunks = useCallback(
    async (uuid: string | null, page: number, pageSize: number): Promise<ChunksPayload | null> => {
      if (!uuid) return null;

      return fetchWithAuth('/api/get_chunks', {
        method: 'POST',
        body: JSON.stringify({ uuid, page, pageSize })
      });
    },
    [fetchWithAuth]
  );

  const fetch_chunk = useCallback(
    async (uuid: string | null): Promise<ChunkPayload | null> => {
      if (!uuid) return null;

      return fetchWithAuth('/api/get_chunk', {
        method: 'POST',
        body: JSON.stringify({ uuid })
      });
    },
    [fetchWithAuth]
  );

  const deleteDocument = useCallback(
    async (uuid: string): Promise<boolean> => {
      const response = await fetchWithAuth('/api/delete_document', {
        method: 'POST',
        body: JSON.stringify({ uuid })
      });

      return !!response;
    },
    [fetchWithAuth]
  );

  const createAgent = useCallback(
    async (
      name: string,
      welcomeMessage: string,
      systemMessage: string,
      errorMessage: string,
      languageId: string,
      folderIds: string[]
    ): Promise<{ status: number; agentId?: string; message?: string }> => {
      const agentPayload = {
        name,
        system_message: systemMessage,
        welcome_message: welcomeMessage,
        error_message: errorMessage,
        language_id: languageId,
        agent_folders: folderIds.map((folderId) => ({
          folder_id: folderId
        }))
      };

      try {
        const response = await fetchWithAuth<{ message: string; agent_id: string }>('/api/create_agent', {
          method: 'POST',
          body: JSON.stringify(agentPayload)
        });

        if (!response) {
          return {
            status: 500,
            message: 'Failed to create agent - no response'
          };
        }

        return {
          status: 200,
          agentId: response.agent_id,
          message: response.message
        };
      } catch (error) {
        console.error('Error creating agent:', error);
        return {
          status: 500,
          message: error instanceof Error ? error.message : 'Unknown error occurred'
        };
      }
    },
    [fetchWithAuth]
  );

  const updateAgent = useCallback(
    async (agentId: string, agent: Agent): Promise<{ status: number; agentId?: string; message?: string }> => {
      try {
        const updatePayload = {
          id: agentId,
          name: agent.name,
          system_message: agent.systemMessage,
          welcome_message: agent.welcomeMessage,
          error_message: agent.errorMessage,
          language_id: agent.languageId,
          agent_folders: agent.agentFolders?.map((af) => ({
            folder_id: af.folderId || af.folder?.id
          }))
        };

        const response = await fetchWithAuth<{ status: number; agent_id?: string; message?: string }>(`/api/update_agent/${agentId}`, {
          method: 'POST',
          body: JSON.stringify(updatePayload)
        });

        if (!response || response.status !== 200) {
          throw new Error(response?.message || 'Failed to update agent');
        }

        return {
          status: 200,
          agentId: response.agent_id,
          message: response.message
        };
      } catch (error) {
        console.error('Error updating agent:', error);
        return {
          status: 500,
          message: error instanceof Error ? error.message : 'Unknown error occurred'
        };
      }
    },
    [fetchWithAuth]
  );

  const deleteAgent = useCallback(
    async (agentId: string): Promise<{ status: number; agentId?: string; message?: string }> => {
      try {
        const response = await fetchWithAuth<{ status: number; agent_id?: string; message?: string }>(`/api/delete_agent/${agentId}`, {
          method: 'POST'
        });

        if (!response || response.status !== 200) {
          throw new Error(response?.message || 'Failed to delete agent');
        }

        return {
          status: 200,
          agentId: response.agent_id,
          message: response.message
        };
      } catch (error) {
        console.error('Error deleting agent:', error);
        return {
          status: 500,
          message: error instanceof Error ? error.message : 'Unknown error occurred'
        };
      }
    },
    [fetchWithAuth]
  );

  return (
    <ApiContext.Provider
      value={{
        fetchWithAuth,
        fetchDatacount,
        fetchFolderNames,
        fetchFolders,
        deleteFolder,
        fetch_chunks,
        fetch_chunk,
        deleteDocument,
        createAgent,
        updateAgent,
        deleteAgent
      }}>
      {children}
    </ApiContext.Provider>
  );
}

export function useApi() {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
}
