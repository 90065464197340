import React, { useState, useEffect, useRef, useCallback } from 'react';
import { XMarkIcon, FolderIcon, ArrowPathIcon, CheckCircleIcon, ChevronUpDownIcon, CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useApi } from '@/context/ApiContext';
import { useQuery } from '@apollo/client';
import { GET_LANGUAGES } from '@/graphql/queries';
import { Agent, Folder, GetFoldersDocument } from '@/gql/graphql';
import { apiClient } from '@/apollo/client';
import { GetLanguagesQuery, GetLanguagesQueryVariables, GetFoldersQuery } from '@/gql/graphql';
import useClickOutside from '@/hooks/useClickOutside';
import { useNavigate } from 'react-router-dom';

interface CreateAgentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (config: AgentConfig) => void;
  selectedFolders: string[];
  isAgentPage?: boolean;
}

export interface AgentConfig {
  name: string;
  welcomeMessage: string;
  systemMessage: string;
  errorMessage: string;
  language: string;
}

const defaultConfig: AgentConfig = {
  name: '',
  welcomeMessage: 'Hello! How can I assist you today?',
  systemMessage:
    'You are a helpful assistant with access to specific knowledge from the provided documents. Answer questions accurately based on this information.',
  errorMessage: 'I apologize, but I encountered an error. Please try again or contact support if the issue persists.',
  language: ''
};

const CreateAgentModal: React.FC<CreateAgentModalProps> = ({ isOpen, onClose, onSubmit, selectedFolders, isAgentPage = false }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [config, setConfig] = useState<AgentConfig>(defaultConfig);
  const [error, setError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [localSelectedFolders, setLocalSelectedFolders] = useState<string[]>(selectedFolders);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { createAgent } = useApi();
  const navigate = useNavigate();

  const { data: languagesData, loading: languagesLoading } = useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GET_LANGUAGES, {
    client: apiClient
  });

  const { data: foldersData } = useQuery<GetFoldersQuery>(GetFoldersDocument, {
    skip: !isAgentPage
  });

  useEffect(() => {
    setLocalSelectedFolders(selectedFolders);
  }, [selectedFolders]);

  useEffect(() => {
    if (languagesData?.findManyLanguages && languagesData.findManyLanguages.length > 0 && !config.language) {
      setConfig((prev) => ({
        ...prev,
        language: languagesData.findManyLanguages[0].id
      }));
    }
  }, [languagesData]);

  useClickOutside(modalRef, (event) => {
    if (isOpen) {
      event.preventDefault();
      onClose();
    }
  });

  useClickOutside(dropdownRef, (event) => {
    if (isDropdownOpen) {
      event.preventDefault();
      setIsDropdownOpen(false);
    }
  });

  const handleFolderSelect = (folderId: string) => {
    setLocalSelectedFolders((prev) => {
      if (prev.includes(folderId)) {
        return prev.filter((id) => id !== folderId);
      }
      return [...prev, folderId];
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      // We don't need to await this because it takes a while to complete
      createAgent(
        config.name,
        config.welcomeMessage,
        config.systemMessage,
        config.errorMessage,
        config.language,
        isAgentPage ? localSelectedFolders : selectedFolders
      );

      setSaveSuccess(true);
      setTimeout(() => {
        setSaveSuccess(false);
        onSubmit(config);
        onClose();
        navigate('/agents');
      }, 1000);
    } catch (error: any) {
      console.error('Error creating agent:', error);
      if (error.message?.includes('Unique constraint failed')) {
        setError('An agent with this name already exists. Please choose a different name.');
      } else {
        setError('Failed to create agent. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateConfig = (field: keyof AgentConfig, value: any) => {
    setConfig((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const renderSelectFolders = () => {
    if (!isAgentPage) {
      return (
        <div className='mb-4 p-4 rounded-lg transition-all duration-300 ease-in-out bg-gray-50'>
          {selectedFolders.length > 0 ? (
            <>
              <h3 className='text-lg font-medium mb-3'>Selected Items</h3>
              <div className='space-y-2'>
                <div className='flex items-center space-x-2 px-3 py-2 bg-gray-100 rounded-lg transition-all duration-300 ease-in-out hover:bg-gray-200'>
                  <FolderIcon className='h-5 w-5 text-yellow-500' />
                  <span>{selectedFolders.length} root folder(s) selected</span>
                </div>
              </div>
            </>
          ) : (
            <div>Select Folders</div>
          )}
        </div>
      );
    }

    return (
      <div className='mt-6 relative'>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Knowledge Base</label>
        <div className='relative'>
          <button
            type='button'
            className='relative w-full cursor-default rounded-md bg-white py-2.5 pl-3 pr-10 text-left border border-gray-300 hover:border-gray-400 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary transition-all duration-200 ease-in-out'
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <span className='block truncate min-h-[1.5rem]'>
              {localSelectedFolders.length === 0 ? (
                <span className='text-gray-500'>Select folders...</span>
              ) : (
                <div className='flex flex-wrap gap-1.5 -m-0.5 p-0.5'>
                  {localSelectedFolders.map((id) => {
                    const folder = foldersData?.findManyFolders.find((f) => f.id === id);
                    return folder ? (
                      <span
                        key={folder.id}
                        className='group inline-flex items-center px-2 py-0.5 rounded bg-uehPrimary bg-opacity-90 text-white text-sm transition-all duration-200 hover:bg-red-500/90 hover:pl-1.5 animate-fadeIn'>
                        <FolderIcon className='h-3.5 w-3.5 mr-1 group-hover:rotate-12 transition-transform duration-200' />
                        <span className='group-hover:line-through'>{folder.name}</span>
                        <div className='group/remove ml-1'>
                          <XMarkIcon
                            className='h-3.5 w-3.5 cursor-pointer opacity-60 group-hover:opacity-100 group-hover:rotate-90 transition-all duration-200'
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFolderSelect(folder.id);
                            }}
                          />
                        </div>
                      </span>
                    ) : null;
                  })}
                </div>
              )}
            </span>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronUpDownIcon
                className={`h-5 w-5 text-gray-400 transition-transform duration-200 ${isDropdownOpen ? 'transform rotate-180' : ''}`}
                aria-hidden='true'
              />
            </span>
          </button>

          <div
            className={`absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm transform origin-top transition-all duration-200 ease-in-out ${
              isDropdownOpen ? 'opacity-100 scale-100 translate-y-0' : 'opacity-0 scale-95 -translate-y-2 pointer-events-none'
            }`}>
            {foldersData?.findManyFolders.length === 0 ? (
              <div className='px-3 py-2 text-sm text-gray-500'>No folders available</div>
            ) : (
              foldersData?.findManyFolders.map((folder) => (
                <div
                  key={folder.id}
                  className={`relative cursor-pointer select-none py-2 pl-3 pr-9 transition-all duration-200 ${
                    localSelectedFolders.includes(folder.id) ? 'bg-uehPrimary/90 text-white' : 'text-gray-900 hover:bg-uehPrimary/20'
                  }`}
                  onClick={() => handleFolderSelect(folder.id)}>
                  <div className='flex items-center'>
                    <FolderIcon
                      className={`h-5 w-5 mr-2 transition-colors duration-200 ${
                        localSelectedFolders.includes(folder.id) ? 'text-white' : 'text-yellow-500'
                      }`}
                    />
                    <span
                      className={`block truncate transition-all duration-200 ${
                        localSelectedFolders.includes(folder.id) ? 'font-medium' : 'font-normal'
                      }`}>
                      {folder.name}
                    </span>
                  </div>

                  {localSelectedFolders.includes(folder.id) ? (
                    <span className='absolute inset-y-0 right-0 flex items-center pr-3 text-white'>
                      <CheckIcon className='h-5 w-5 animate-fadeIn' aria-hidden='true' />
                    </span>
                  ) : (
                    <span className='absolute inset-y-0 right-0 flex items-center pr-3 opacity-0 group-hover:opacity-60 transition-opacity duration-200'>
                      <PlusIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                    </span>
                  )}
                </div>
              ))
            )}
          </div>
        </div>

        {localSelectedFolders.length > 0 && (
          <div className='mt-2 text-sm text-gray-600 flex items-center space-x-1 animate-fadeIn'>
            <FolderIcon className='h-4 w-4 text-yellow-500' />
            <span>
              {localSelectedFolders.length} folder{localSelectedFolders.length !== 1 ? 's' : ''} selected
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`} aria-labelledby='modal-title' role='dialog' aria-modal='true'>
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ease-in-out
          ${isOpen ? 'opacity-25' : 'opacity-0'}`}
        onClick={onClose}
      />

      <div className='fixed inset-0 overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4'>
          <div
            ref={modalRef}
            className={`relative w-full max-w-2xl bg-white rounded-lg shadow-xl 
              transition-all duration-300 ease-in-out transform
              ${isOpen ? 'translate-y-0 opacity-100 scale-100' : 'translate-y-4 opacity-0 scale-95'}`}>
            <div className='p-6'>
              <div className='flex justify-between items-center mb-4'>
                <div className='text-lg font-semibold transition duration-500 ease-in-out transform'>Create Knowledge Agent</div>
                <button onClick={onClose} className='text-gray-500 hover:text-gray-700 transition-colors'>
                  <XMarkIcon className='h-6 w-6' />
                </button>
              </div>

              <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                <div className='sm:flex sm:items-start'>
                  <div className='w-full'>
                    {renderSelectFolders()}
                    <div className='mb-4'>
                      <h3 className='text-lg font-medium text-gray-900'>Basic Settings</h3>
                    </div>

                    <form onSubmit={handleSubmit} className='space-y-4'>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Agent Name</label>
                        <input
                          type='text'
                          value={config.name}
                          onChange={(e) => updateConfig('name', e.target.value)}
                          className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                          required
                        />
                      </div>

                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Welcome Message</label>
                        <textarea
                          value={config.welcomeMessage}
                          onChange={(e) => updateConfig('welcomeMessage', e.target.value)}
                          className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                          rows={2}
                        />
                      </div>

                      <div>
                        <label className='block text-sm font-medium text-gray-700'>System Message</label>
                        <textarea
                          value={config.systemMessage}
                          onChange={(e) => updateConfig('systemMessage', e.target.value)}
                          className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                          rows={4}
                        />
                      </div>

                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Error Message</label>
                        <textarea
                          value={config.errorMessage}
                          onChange={(e) => updateConfig('errorMessage', e.target.value)}
                          className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                          rows={2}
                        />
                      </div>

                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Language</label>
                        <select
                          value={config.language}
                          onChange={(e) => updateConfig('language', e.target.value)}
                          className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                          disabled={languagesLoading}>
                          {languagesLoading ? (
                            <option>Loading languages...</option>
                          ) : (
                            languagesData?.findManyLanguages.map((language) => (
                              <option key={language.id} value={language.id}>
                                {language.name}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      {error && <div className='text-red-600 text-sm mt-2'>{error}</div>}

                      <div className='flex justify-end gap-4 mt-6'>
                        <button onClick={onClose} className='px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50'>
                          Cancel
                        </button>
                        <button
                          type='submit'
                          disabled={isSubmitting}
                          className={`
                            px-4 py-2 rounded-md font-semibold
                            flex items-center justify-center min-w-[140px]
                            transition-all duration-200
                            ${
                              saveSuccess
                                ? 'bg-green-500 hover:bg-green-600'
                                : isSubmitting
                                  ? 'bg-gray-400 cursor-not-allowed'
                                  : 'bg-uehPrimary hover:bg-uehPrimary/90'
                            }
                            text-white disabled:opacity-50
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-uehPrimary
                          `}>
                          {isSubmitting ? (
                            <div className='flex items-center'>
                              <ArrowPathIcon className='animate-spin -ml-1 mr-2 h-4 w-4 text-white' />
                              Creating...
                            </div>
                          ) : saveSuccess ? (
                            <div className='flex items-center'>
                              <CheckCircleIcon className='w-5 h-5 mr-2' />
                              Created!
                            </div>
                          ) : (
                            'Create Agent'
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAgentModal;
