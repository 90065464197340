import { Folder, Document } from "@/gql/graphql";
import { v4 as uuidv4 } from 'uuid';

export const transformFile2Base64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
  });
};

interface ITransformBase64ToFileProps {
  dataUrl: string;
  filename?: string;
}
export const transformBase64ToFile = ({ dataUrl, filename = 'file' }: ITransformBase64ToFileProps): File => {
  const arr = dataUrl.split(',');
  const bstr = atob(arr[1]);
  const n = bstr.length;
  const u8arr = new Uint8Array(n);

  const mime = arr[0].match(/:(.*?);/);
  const mimeType = mime ? mime[1] : 'image/png';

  for (let i = 0; i < n; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }
  return new File([u8arr], filename, { type: mimeType });
};

export const normFile = (e: any): File[] => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.target?.files || [];
};

export interface FileInfo {
  uid: string;
  name: string;
  thumbUrl: string;
  status: 'done' | 'error' | 'uploading';
}

export const getUploadFileListFromBase64 = (avatar: string): FileInfo[] => {
  let fileList: FileInfo[] = [];

  if (avatar) {
    fileList = [{ uid: '1', name: 'file', thumbUrl: avatar, status: 'done' }];
  }

  return fileList;
};

export const getBase64FromUploadFileList = async (fileList?: FileInfo[]): Promise<string> => {
  if (Array.isArray(fileList) && fileList.length > 0) {
    const file = fileList[0];
    if (file.thumbUrl) {
      return file.thumbUrl;
    }
  }

  return '';
};

interface IDownloadFileProps {
  url: string;
  filename?: string;
  target?: string;
}
export const downloadFile = ({ url, filename, target }: IDownloadFileProps): void => {
  const downloadElement = document.createElement('a');
  downloadElement.style.display = 'none';
  downloadElement.href = url;
  if (target) {
    downloadElement.target = '_blank';
  }
  downloadElement.rel = 'noopener noreferrer';
  if (filename) {
    downloadElement.download = filename;
  }
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
};

const Units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const formatBytes = (x: string | number): string => {
  let l = 0;
  let n = (typeof x === 'string' ? parseInt(x, 10) : x) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + Units[l];
};

export const generateUniqueId = (): string => {
  return uuidv4();
};

export const formatNumberWithThousandsSeparator = (value: number): string => {
  return value.toLocaleString();
};

export const formatDate = (value: string): string => {
  return new Date(value).toLocaleDateString();
};

export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 B';
  
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

// Folder tree printing utility
export const printFolderTree = (node: Folder, level = 0, isLast = true, prefix = ''): string => {
  let result = '';
  
  // Add current folder with proper indentation
  const indent = level === 0 ? '' : prefix + (isLast ? '└── ' : '├── ');
  const totalFiles = getTotalFilesCount(node);
  result += `${indent}📁 ${node.name} (${totalFiles} files)\n`;
  
  // Calculate new prefix for children (both files and subfolders)
  const childPrefix = level === 0 ? '    ' : prefix + (isLast ? '    ' : '│   ');
  
  // Add documents under this folder
  if (node.documents && node.documents.length > 0) {
    node.documents.forEach((doc, index) => {
      const isLastItem = index === node.documents!.length - 1 && (!node.subFolders || node.subFolders.length === 0);
      const docPrefix = childPrefix + (isLastItem ? '└── ' : '├── ');
      
      // Choose icon based on document status
      let icon = '📄'; // Default icon for completed documents
      if (doc.status === 'ERROR') {
        icon = '⚠️'; // Warning icon for error status
      } else if (doc.status !== 'COMPLETED') {
        icon = '⏳'; // Hourglass icon for temporal/processing files
      }
      
      result += `${docPrefix}${icon} ${doc.title}\n`;
    });
  }
  
  // Add subfolders
  if (node.subFolders && node.subFolders.length > 0) {
    node.subFolders.forEach((subfolder, index) => {
      const isLastSubfolder = index === node.subFolders!.length - 1;
      result += printFolderTree(subfolder, level + 1, isLastSubfolder, childPrefix);
    });
  }
  
  return result;
};

export const getTotalFilesCount = (folder: Folder): number => {
  let total = folder.documents?.length || 0;
  
  if (folder.subFolders && folder.subFolders.length > 0) {
    folder.subFolders.forEach(subfolder => {
      total += getTotalFilesCount(subfolder);
    });
  }
  
  return total;
};
