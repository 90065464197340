import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import Markdown from 'react-markdown';
import { Prism, SyntaxHighlighterProps } from 'react-syntax-highlighter';
import remarkGfm from 'remark-gfm';
import { visitParents } from 'unist-util-visit-parents';
import { IChunk } from '@/types/knowledge';

const SyntaxHighlighter = Prism as any as React.FC<SyntaxHighlighterProps>;

const MarkdownContent = ({
  clickDocumentButton,
  content,
  loading
}: {
  content: string;
  loading: boolean;
  clickDocumentButton?: (documentId: string, chunk: IChunk) => void;
}) => {
  const contentWithCursor = useMemo(() => {
    let text = content;
    if (text === '') {
      text = 'Searching...';
    }
    return loading ? text?.concat('~~2$$') : text;
  }, [content, loading]);

  const handleDocumentButtonClick = useCallback(
    (documentId: string, chunk: IChunk, isPdf: boolean) => () => {
      if (!isPdf) {
        return;
      }
      clickDocumentButton?.(documentId, chunk);
    },
    [clickDocumentButton]
  );

  const rehypeWrapReference = () => {
    return function wrapTextTransform(tree: any) {
      visitParents(tree, 'text', (node, ancestors) => {
        const latestAncestor = ancestors.at(-1);
        if (latestAncestor.tagName !== 'custom-typography' && latestAncestor.tagName !== 'code') {
          node.type = 'element';
          node.tagName = 'custom-typography';
          node.properties = {};
          node.children = [{ type: 'text', value: node.value }];
        }
      });
    };
  };

  return (
    <Markdown
      rehypePlugins={[rehypeWrapReference]}
      remarkPlugins={[remarkGfm]}
      components={
        {
          code(props: any) {
            const { children, className, node, ...rest } = props;
            const match = /language-(\w+)/.exec(className || '');
            return match ? (
              <SyntaxHighlighter {...rest} PreTag='div' language={match[1]} className='rounded-md'>
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            ) : (
              <code {...rest} className={classnames('bg-gray-100 rounded px-1 py-0.5', className)}>
                {children}
              </code>
            );
          },
          h1: ({ children }: { children: React.ReactNode }) => <h1 className='font-iciel font-bold'>{children}</h1>,
          h2: ({ children }: { children: React.ReactNode }) => <h2 className='font-iciel font-semibold'>{children}</h2>,
          h3: ({ children }: { children: React.ReactNode }) => <h3 className='font-iciel font-medium'>{children}</h3>,
          p: ({ children }: { children: React.ReactNode }) => <p className='font-myriad'>{children}</p>
        } as any
      }
      className='markdown-content'>
      {contentWithCursor}
    </Markdown>
  );
};

export default MarkdownContent;

interface PopoverProps {
  children: React.ReactNode;
  content: React.ReactNode;
}

// Custom Popover component
const Popover = ({ children, content }: PopoverProps) => {
  return (
    <div className='relative folder'>
      {children}
      <div className='absolute z-10 invisible group-hover:visible bg-white border border-gray-200 rounded-md shadow-lg p-4 mt-2'>{content}</div>
    </div>
  );
};

// Custom hook to select file thumbnails (implement this based on your state management solution)
const useSelectFileThumbnails = (fileThumbnails: Record<string, string>) => {
  // Use useMemo to prevent unnecessary recalculations
  return useMemo(() => fileThumbnails, [fileThumbnails]);
};

// Custom animation for blinking cursor
const styles = `
@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
.animate-blink {
  animation: blink 1s infinite;
}
`;

// Add the styles to the document
if (typeof document !== 'undefined') {
  const styleElement = document.createElement('style');
  styleElement.textContent = styles;
  document.head.appendChild(styleElement);
}
