import { Folder } from '@/gql/graphql';

export const createFolderTree = (folder: Folder): Folder => {
  // Remove the "text" property from every document
  const cleanDocuments = folder.documents?.map(({ text, ...rest }) => ({
    ...rest
  }));

  // Recursively map subfolders if they exist
  const cleanSubFolders = folder.subFolders?.map((subFolder) => createFolderTree(subFolder));

  // Return a new Folder object that excludes text fields on documents
  return {
    ...folder,
    documents: cleanDocuments,
    subFolders: cleanSubFolders
  };
};
