import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import uehLogo from '../assets/images/logo/logo-ueh/logo-ueh-xanh.png';
import { useKeycloakAuth } from '@/context/KeycloakAuthProvider';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export const SigninPage = () => {
  const { login, isSignedIn } = useKeycloakAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      // Wait a bit to ensure Keycloak is properly initialized
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      if (isSignedIn) {
        navigate('/chat');
      } else {
        login();
      }
      setIsLoading(false);
    };

    checkAuth();
  }, [isSignedIn, navigate, login]);

  if (isLoading) {
    return (
      <main className='grow bg-white dark:bg-slate-900'>
        <div className='flex min-h-screen items-center justify-center bg-gray-50'>
          <div className='w-full max-w-md space-y-8'>
            <div className='flex flex-col items-center'>
              <img src={uehLogo} alt='UEH Logo' className='h-16 w-24' />
              <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>Welcome to UEH Chatbot</h2>
              <div className='mt-4 flex items-center justify-center'>
                <ArrowPathIcon className='h-8 w-8 text-uehPrimary animate-spin' />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }

  return null;
};

export default SigninPage;
