import { useCallback, useEffect, useMemo, useState } from 'react';
import UploadModal from '../../components/file/UploadModal';
import FolderCreateModal from '../../components/file/FolderCreateModal';
import CreateAgentModal, { AgentConfig } from '../../components/file/CreateAgentModal';
import FolderTreeView from '../../components/file/FolderTreeView';
import {
  Document,
  DocumentStatus,
  Folder,
  GetDocumentsQuery,
  GetFoldersDocument,
  GetFoldersQuery,
  CreateRootFolderMutation,
  CreateFolderMutation
} from '@/gql/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { GET_DOCUMENTS, CREATE_ROOT_FOLDER, CREATE_FOLDER } from '../../graphql/queries';

import { FolderIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { useKeycloakAuth } from '@/context/KeycloakAuthProvider';
import { getExtension } from '@/utils/document_util';
import { generateUniqueId, printFolderTree } from '@/utils/file_util';
import { useChat } from '@/context/ChatProvider';
import { useApi } from '@/context/ApiContext';

const FilePage = () => {
  const { loading: foldersLoading, error: foldersError, data: foldersData, refetch: refetchFolders } =
    useQuery<GetFoldersQuery>(GetFoldersDocument);
  const { user } = useKeycloakAuth();

  // Add documents query
  const { loading: documentsLoading, data: documentsData, refetch: refetchDocuments } =
    useQuery<GetDocumentsQuery>(GET_DOCUMENTS);

  const {
    uploadFolderTree,
    deleteDocument,
    fileStatuses,
    setFileStatus,
    clearFileStatuses
  } = useChat();

  const { deleteFolder } = useApi();

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);
  const [folderDocuments, setFolderDocuments] = useState<string[]>([]);
  const [fileRenameVisible, setFileRenameVisible] = useState(false);
  const [folderCreateModalVisible, setFolderCreateModalVisible] = useState(false);
  const [fileUploadVisible, setFileUploadVisible] = useState(false);
  const [moveFileVisible, setMoveFileVisible] = useState(false);
  const [uploadTargetFolderId, setUploadTargetFolderId] = useState<string>('');
  const [isMobileView, setIsMobileView] = useState(false);
  const [showCreateAgentModal, setShowCreateAgentModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [openFolders, setOpenFolders] = useState<Record<string, boolean>>({});

  const [createRootFolder] = useMutation<CreateRootFolderMutation>(CREATE_ROOT_FOLDER);
  const [createSubFolder] = useMutation<CreateFolderMutation>(CREATE_FOLDER);

  // Build an in-memory folder tree to be displayed
  const folderTree = useMemo(() => {
    if (!foldersData?.findManyFolders || !documentsData?.findManyDocuments) {
      return [];
    }

    const folders = foldersData.findManyFolders;
    const documents = documentsData.findManyDocuments;

    // Create a map of folders by their IDs
    const folderMap = new Map<string, Folder>();

    // Initialize all folders, setting up empty arrays
    folders.forEach((f) => {
      folderMap.set(f.id, {
        ...f,
        subFolders: [],
        documents: [],
        agentFolders: []
      });
    });

    // Assign documents to their respective folders
    documents.forEach((doc: Document) => {
      const parentFolder = doc.folderId ? folderMap.get(doc.folderId) : null;
      if (parentFolder) {
        parentFolder.documents?.push(doc);
      }
    });

    // Build the hierarchy by linking subfolders to parents
    folders.forEach((f) => {
      if (f.parentId) {
        const parentFolder = folderMap.get(f.parentId);
        if (parentFolder) {
          parentFolder.subFolders?.push(folderMap.get(f.id)!);
        }
      }
    });

    // Extract only root-level folders (has no parentId)
    const rootFolders = folders
      .filter((f) => !f.parentId)
      .map((f) => folderMap.get(f.id)!)
      .filter(Boolean);

    console.log('Folder tree:', rootFolders);
    return rootFolders;
  }, [foldersData?.findManyFolders, documentsData?.findManyDocuments]);

  // Debug print folder tree
  useEffect(() => {
    if (folderTree.length > 0) {
      console.log('Current Folder Tree Structure:');
      folderTree.forEach((folder, index) => {
        console.log(printFolderTree(folder, 0, index === folderTree.length - 1));
      });
    }
  }, [folderTree]);

  const toggleFolder = (folderId: string) => {
    setOpenFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Debug logs for documents
  useEffect(() => {
    const docs = documentsData?.findManyDocuments;
    if (docs && docs.length > 0) {
      console.log('Fetched documents:', docs);
    }
  }, [documentsData]);

  // Clear file statuses whenever new documents are loaded
  useEffect(() => {
    if (documentsData?.findManyDocuments) {
      clearFileStatuses();
    }
  }, [documentsData?.findManyDocuments, clearFileStatuses]);

  const handleFileRename = () => {
    console.info('Renaming file');
  };

  // Utility to convert a nested path into subfolders within the root Folder
  const findOrCreateSubFolder = (parent: Folder, folderName: string): Folder => {
    // Attempt to find an existing subfolder with the same name
    let subFolder = parent.subFolders?.find((sf) => sf.name === folderName);
    if (!subFolder) {
      subFolder = {
        __typename: 'Folder',
        id: generateUniqueId(),
        name: folderName,
        description: null,
        parentId: parent.id,
        userId: parent.userId,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        agentFolders: [],
        subFolders: [],
        documents: []
      };
      parent.subFolders?.push(subFolder);
    }
    return subFolder;
  };

  // Converts each file into a top-level or nested subfolder with a Document object
  const handleFileUpload = async (files: File[], folderId: string) => {
    const selectedFolder = foldersData?.findManyFolders.find((g) => g.id === folderId);
    if (!selectedFolder) return;

    setFileUploadVisible(false);
    clearFileStatuses();

    try {
      const fileMap = new Map<string, { file: File; content: string }>();

      // Preprocess and store each file in a map
      for (const file of files) {
        try {
          if (!validateFile(file)) {
            throw new Error('Invalid file type or size');
          }
          const fileContent = await readFileAsBase64(file);
          const relativePath = file.webkitRelativePath || file.name;
          fileMap.set(relativePath, { file, content: fileContent });
        } catch (err) {
          const errorFileId = generateUniqueId();
          console.error(`Error processing file ${file.name}:`, err);
          setFileStatus(errorFileId, {
            isUploading: false,
            document: {
              __typename: 'Document',
              id: errorFileId,
              title: file.name,
              fileSize: file.size,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              status: DocumentStatus.Error,
              userId: user?.id || '',
              extension: getExtension(file.name),
              folderId: folderId
            },
            error: err instanceof Error ? err.message : 'Unknown error occurred',
            folderId: folderId,
            progress: 0
          });
        }
      }

      // Create an in-memory Folder from the selectedFolder
      const rootFolder: Folder = {
        __typename: 'Folder',
        id: selectedFolder.id,
        name: selectedFolder.name,
        description: selectedFolder.description ?? null,
        parentId: selectedFolder.parentId ?? null,
        userId: selectedFolder.userId,
        createdAt: selectedFolder.createdAt,
        updatedAt: selectedFolder.updatedAt,
        agentFolders: [],
        subFolders: [],
        documents: []
      };

      // Walk through each file path, creating subfolders and documents
      for (const [path, { file, content }] of fileMap) {
        const pathParts = path.split('/');
        const fileName = pathParts.pop() || '';
        let currentFolder = rootFolder;

        // Create or descend into subfolders
        for (const folderName of pathParts) {
          if (!folderName || folderName === selectedFolder.name) continue;
          currentFolder = findOrCreateSubFolder(currentFolder, folderName);
        }

        // Finally, create a Document in the current folder
        const doc: Document = {
          __typename: 'Document',
          id: generateUniqueId(),
          title: fileName,
          extension: getExtension(fileName),
          fileSize: file.size,
          folderId: currentFolder.id,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          userId: user?.id || '',
          status: DocumentStatus.Pending,
          source: 'upload',
          text: content
        };
        currentFolder.documents?.push(doc);
      }

      // Now the rootFolder has everything nested properly
      await uploadFolderTree(rootFolder);

      // Refresh folder and document listings
      await Promise.all([refetchFolders(), refetchDocuments()]);
    } catch (error) {
      console.error('Error uploading folder tree:', error);
    }
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        const base64Content = result.split(',')[1];
        resolve(base64Content);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const validateFile = (file: File): boolean => {
    const maxSize = 50 * 1024 * 1024; // 50MB
    const allowedTypes = ['.pdf', '.md'];

    if (file.size > maxSize) {
      throw new Error('File size exceeds 50MB limit');
    }

    const extension = getExtension(file.name).toLowerCase();
    if (!allowedTypes.includes(`.${extension}`)) {
      throw new Error(`File type .${extension} is not supported`);
    }

    return true;
  };

  // If all files are done uploading, refetch documents
  useEffect(() => {
    const hasUploadingFiles = Object.values(fileStatuses).some((status) => status.isUploading);
    if (!hasUploadingFiles) {
      refetchDocuments();
    }
  }, [fileStatuses, refetchDocuments]);

  const [currentRecord, setCurrentRecord] = useState<Document | null>(null);

  // Document removal
  const handleRemoveFile = useCallback(
    async (recordIds: string[]) => {
      try {
        for (const recordId of recordIds) {
          await deleteDocument(recordId);
        }
        refetchDocuments();
      } catch (error) {
        console.error('Error deleting document:', error);
      }
    },
    [deleteDocument, refetchDocuments]
  );

  // Folder removal
  const handleFolderDelete = async (folderIds: string[]) => {
    if (!window.confirm('Are you sure you want to delete the selected folders?')) {
      return;
    }

    try {
      for (const folderId of folderIds) {
        const result = await deleteFolder(folderId);
        if (result.status !== 200) {
          alert(result.status_msg);
          return;
        }
      }

      setSelectedFolders([]);
      setFolderDocuments([]);
      await refetchFolders();
    } catch (error) {
      console.error('Error deleting folders:', error);
      alert('Failed to delete folders: Network error');
    }
  };

  // Button row (New folder, Create agent, etc.)
  const renderActionButtons = () => {
    const hasRootFolders = folderTree.length > 0;
    const hasSelectedRootFolders =
      selectedFolders.length > 0 &&
      selectedFolders.every((folderId) => folderTree.some((folder) => folder.id === folderId));

    return (
      <div className='flex justify-between items-center mb-4'>
        <div className='flex space-x-2'>
          <button
            onClick={() => setFolderCreateModalVisible(true)}
            className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'
          >
            <FolderIcon className='h-5 w-5 mr-2' />
            New Folder
          </button>
        </div>
        <button
          onClick={() => setShowCreateAgentModal(true)}
          disabled={!hasSelectedRootFolders}
          className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
            hasSelectedRootFolders ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-400 cursor-not-allowed'
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
          title={!hasSelectedRootFolders ? 'Please select one or more root folders' : 'Create agent'}
        >
          <PlusCircleIcon className='h-5 w-5 mr-2' />
          Create Agent
        </button>
      </div>
    );
  };

  // Create new folder (root or subfolder)
  const handleCreateFolder = async (name: string, description: string) => {
    try {
      const selectedFolderId = selectedFolders[0];
      let response;

      if (selectedFolderId) {
        response = await createSubFolder({
          variables: {
            name,
            description,
            userId: user?.id || '',
            parentId: selectedFolderId
          }
        });
      } else {
        response = await createRootFolder({
          variables: {
            name,
            description,
            userId: user?.id || ''
          }
        });
      }

      if (response.data) {
        await refetchFolders();
        setFolderCreateModalVisible(false);
      } else {
        console.error('Failed to create folder: No data returned');
      }
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  const handleFolderSelect = (folderId: string, selected: boolean) => {
    if (selected) {
      setSelectedFolders([...selectedFolders, folderId]);
      setSelectedDocuments([]);
    } else {
      setSelectedFolders(selectedFolders.filter((id) => id !== folderId));
      if (selectedFolders.length === 1) {
        setFolderDocuments([]);
      }
    }
  };

  // Creating a new agent
  const handleCreateAgent = async (config: AgentConfig) => {
    try {
      console.log('Creating agent with root folders:', selectedFolders);
      console.log('Agent config:', config);
      setSelectedFolders([]);
      setShowCreateAgentModal(false);
    } catch (error) {
      console.error('Error creating agent:', error);
    }
  };

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='flex-1 overflow-y-auto p-4'>
        {renderActionButtons()}

        {foldersLoading || documentsLoading ? (
          <div>Loading...</div>
        ) : foldersError ? (
          <div>Error loading folders</div>
        ) : (
          <FolderTreeView
            folderTree={folderTree}
            openFolders={openFolders}
            selectedDocuments={selectedDocuments}
            selectedFolders={selectedFolders}
            fileStatuses={Object.fromEntries(
              Object.entries(fileStatuses).map(([recordId, status]) => [
                recordId,
                { ...status, document: status.document }
              ])
            )}
            onToggleFolder={toggleFolder}
            onFolderSelection={handleFolderSelect}
            onFolderDelete={handleFolderDelete}
            onUploadToFolder={(folderId) => {
              setUploadTargetFolderId(folderId);
              setFileUploadVisible(true);
            }}
            setCurrentRecord={setCurrentRecord}
            showRenameModal={() => setFileRenameVisible(true)}
            showMoveFileModal={() => setMoveFileVisible(true)}
            handleRemoveFile={handleRemoveFile}
          />
        )}
      </div>

      <UploadModal
        visible={fileUploadVisible}
        hideModal={() => setFileUploadVisible(false)}
        loading={isUploading}
        folderId={uploadTargetFolderId}
        onOk={handleFileUpload}
      />

      <FolderCreateModal
        visible={folderCreateModalVisible}
        hideModal={() => setFolderCreateModalVisible(false)}
        loading={false}
        onOk={handleCreateFolder}
      />

      <CreateAgentModal
        isOpen={showCreateAgentModal}
        onClose={() => setShowCreateAgentModal(false)}
        onSubmit={handleCreateAgent}
        selectedFolders={selectedFolders}
      />
    </div>
  );
};

export default FilePage;
