import { useState, useEffect } from 'react';
import { useApi } from '@/context/ApiContext';
import { useQuery } from '@apollo/client';
import { GET_LANGUAGES } from '@/graphql/queries';
import { Agent, GetLanguagesQuery } from '@/gql/graphql';
import Modal from '../modal/Modal';

interface EditAgentModalProps {
  isOpen: boolean;
  agent: Agent;
  onClose: () => void;
  onAgentUpdated: () => void;
}

interface AgentForm {
  name: string;
  welcomeMessage: string;
  systemMessage: string;
  errorMessage: string;
  languageId: string;
}

const EditAgentModal = ({ isOpen, agent, onClose, onAgentUpdated }: EditAgentModalProps) => {
  const [form, setForm] = useState<AgentForm>({
    name: '',
    welcomeMessage: '',
    systemMessage: '',
    errorMessage: '',
    languageId: ''
  });
  const [loading, setLoading] = useState(false);
  const { updateAgent } = useApi();

  const { data: languagesData } = useQuery<GetLanguagesQuery>(GET_LANGUAGES);

  useEffect(() => {
    if (agent) {
      setForm({
        name: agent.name || '',
        welcomeMessage: agent.welcomeMessage || '',
        systemMessage: agent.systemMessage || '',
        errorMessage: agent.errorMessage || '',
        languageId: agent.languageId || ''
      });
    }
  }, [agent]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await updateAgent(agent.id, {
        ...agent,
        ...form
      });
      onAgentUpdated();
    } catch (error) {
      console.error('Error updating agent:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={isOpen}
      title="Edit Agent"
      onClose={onClose}
      onOk={handleSubmit}
      confirmLoading={loading}
      width={'600'}
    >
      <div className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={form.name}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-uehPrimary focus:ring-uehPrimary sm:text-sm"
            required
          />
        </div>

        <div>
          <label htmlFor="welcomeMessage" className="block text-sm font-medium text-gray-700">
            Welcome Message
          </label>
          <textarea
            id="welcomeMessage"
            name="welcomeMessage"
            value={form.welcomeMessage}
            onChange={handleInputChange}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-uehPrimary focus:ring-uehPrimary sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="systemMessage" className="block text-sm font-medium text-gray-700">
            System Message
          </label>
          <textarea
            id="systemMessage"
            name="systemMessage"
            value={form.systemMessage}
            onChange={handleInputChange}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-uehPrimary focus:ring-uehPrimary sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="errorMessage" className="block text-sm font-medium text-gray-700">
            Error Message
          </label>
          <textarea
            id="errorMessage"
            name="errorMessage"
            value={form.errorMessage}
            onChange={handleInputChange}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-uehPrimary focus:ring-uehPrimary sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="languageId" className="block text-sm font-medium text-gray-700">
            Language
          </label>
          <select
            id="languageId"
            name="languageId"
            value={form.languageId}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-uehPrimary focus:ring-uehPrimary sm:text-sm"
            required
          >
            <option value="">Select a language</option>
            {languagesData?.findManyLanguages?.map((language) => (
              <option key={language.id} value={language.id}>
                {language.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Modal>
  );
};

export default EditAgentModal;
