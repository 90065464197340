import { gql } from '@apollo/client';

export const GET_CHAT_ROOMS = gql`
  query GetChatRooms {
    findManyChatRooms(args: { include: { lastMessage: true }, orderBy: { updatedAt: "desc" } }) {
      id
      name
      userId
      agentId
      lastMessageId
      lastMessage {
        id
        text
        role
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_MESSAGES_BY_CHATROOM = gql`
  query GetMessagesByChatRoom($chatRoomId: String!) {
    findManyMessages(args: { where: { chatRoomId: $chatRoomId }, orderBy: { createdAt: "desc" } }) {
      id
      text
      role
      userId
      chatRoomId
      createdAt
      updatedAt
    }
  }
`;

export const GET_FOLDERS = gql`
  query GetFolders {
    findManyFolders(args: { orderBy: { createdAt: "desc" }, include: { subFolders: true, documents: true } }) {
      id
      name
      description
      createdAt
      updatedAt
      parentId
      userId
      documents {
        id
        title
        extension
        fileSize
        status
        createdAt
        updatedAt
        folderId
        userId
      }
      subFolders {
        id
        name
        description
        createdAt
        updatedAt
        parentId
        userId
      }
    }
  }
`;

export const CREATE_ROOT_FOLDER = gql`
  mutation CreateRootFolder($name: String!, $description: String!, $userId: String!) {
    createFolder(
      args: {
        data: {
          name: $name,
          description: $description,
          userId: $userId,
          parentId: null
        }
      }
    ) {
      id
      name
      description
      parentId
      userId
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_FOLDER = gql`
  mutation CreateFolder($name: String!, $description: String!, $userId: String!, $parentId: String) {
    createFolder(
      args: {
        data: {
          name: $name,
          description: $description,
          userId: $userId,
          parentId: $parentId
        }
      }
    ) {
      id
      name
      description
      parentId
      userId
      createdAt
      updatedAt
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query GetDocuments($folderId: String, $page: Int, $pageSize: Int) {
    findManyDocuments(
      args: { 
        where: { folderId: $folderId },
        orderBy: { createdAt: "desc" },
        skip: $page,
        take: $pageSize
      }
    ) {
      id
      title
      extension
      fileSize
      status
      folderId
      userId
      source
      text
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: String!) {
    deleteDocument(args: { where: { id: $id } }) {
      id
      title
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($id: String!, $data: JSONObject!) {
    updateDocument(args: { where: { id: $id }, data: $data }) {
      id
      title
      status
      updatedAt
    }
  }
`;

export const GET_LANGUAGES = gql`
  query GetLanguages {
    findManyLanguages(args: { orderBy: { name: "asc" } }) {
      id
      code
      name
    }
  }
`;

export const GET_AGENTS = gql`
  query GetAgents {
    findManyAgents(args: { orderBy: { createdAt: "desc" }, include: { language: true } }) {
      id
      name
      welcomeMessage
      systemMessage
      errorMessage
      createdById
      languageId
      language {
        id
        name
        code
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_CHAT_ROOM = gql`
  mutation CreateChatRoom($name: String!, $userId: String!, $agentId: String!) {
    createChatRoom(
      args: {
        data: {
          name: $name,
          user: {
            connect: { id: $userId }
          },
          agent: {
            connect: { id: $agentId }
          }
        }
      }
    ) {
      id
      name
      userId
      agentId
      createdAt
      updatedAt
      agent {
        id
        name
        systemMessage
        welcomeMessage
        errorMessage
      }
    }
  }
`;

export const DELETE_CHAT_ROOM_MESSAGES = gql`
  mutation DeleteChatRoomMessages($chatRoomId: String!) {
    deleteManyMessages(
      args: {
        where: { chatRoomId: $chatRoomId }
      }
    ) {
      count
    }
  }
`;

export const DELETE_CHAT_ROOM = gql`
  mutation DeleteChatRoom($id: String!) {
    deleteChatRoom(args: { where: { id: $id } }) {
      id
      name
      userId
      lastMessageId
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_INFO = gql`
  query GetUserInfo($id: String) {
    findFirstUser(
      args: {
        where: { id: $id }
      }
    ) {
      id
      email
      username
      firstName
      lastName
      role
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($id: String!, $nickname: String, $firstName: String, $lastName: String) {
    updateUser(
      args: {
        where: { id: $id }
        data: {
          username: $nickname
          firstName: $firstName
          lastName: $lastName
        }
      }
    ) {
      id
      email
      username
      firstName
      lastName
      role
      createdAt
      updatedAt
    }
  }
`;
