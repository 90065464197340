import { useState } from 'react';
import { Agent } from '@/gql/graphql';
import { useApi } from '@/context/ApiContext';
import { 
  PencilIcon, 
  TrashIcon, 
  ChatBubbleLeftRightIcon,
  CalendarIcon,
  LanguageIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline';
import EditAgentModal from './EditAgentModal';

interface AgentListProps {
  agents: Agent[];
  onAgentUpdated: () => void;
  isAgentBeingCreated: (agent: Agent) => boolean;
  getAgentStatus: (agent: Agent) => { state: string; status: string; } | null;
}

const AgentList = ({ agents, onAgentUpdated, isAgentBeingCreated, getAgentStatus }: AgentListProps) => {
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { deleteAgent } = useApi();

  const handleEdit = (agent: Agent) => {
    setSelectedAgent(agent);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (agentId: string) => {
    if (window.confirm('Are you sure you want to delete this agent?')) {
      try {
        await deleteAgent(agentId);
        onAgentUpdated();
      } catch (error) {
        console.error('Error deleting agent:', error);
      }
    }
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedAgent(null);
  };

  const handleAgentUpdated = () => {
    onAgentUpdated();
    handleCloseEditModal();
  };

  if (agents.length === 0) {
    return (
      <div className="mt-8 text-center">
        <p className="text-gray-500">No agents found. Create one to get started!</p>
      </div>
    );
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="mt-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {agents.map((agent) => {
          const status = getAgentStatus(agent);
          return (
            <div
              key={agent.id}
              className={`group bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 ease-in-out hover:bg-uehPrimary/5 border border-gray-300 hover:border-uehPrimary/30 relative overflow-hidden animate-fadeIn ${
                isAgentBeingCreated(agent) ? 'opacity-50' : ''
              }`}
            >
              {/* Card Header */}
              <div className="px-6 py-4 flex items-start justify-between border-b border-gray-300 group-hover:border-uehPrimary/30 transition-colors duration-300">
                <div className="flex-1">
                  <h3 className="text-lg font-semibold text-gray-900 group-hover:text-uehPrimary transition-colors duration-300">
                    {agent.name}
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-500">
                    <LanguageIcon className="h-4 w-4 mr-1.5 text-gray-400" />
                    <span>{agent.language?.name || 'N/A'}</span>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {!isAgentBeingCreated(agent) && (
                    <>
                      <button
                        onClick={() => handleEdit(agent)}
                        className="p-1.5 rounded-lg text-gray-400 hover:text-uehPrimary hover:bg-uehPrimary/10 transition-all duration-200"
                      >
                        <PencilIcon className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDelete(agent.id)}
                        className="p-1.5 rounded-lg text-gray-400 hover:text-red-600 hover:bg-red-50 transition-all duration-200"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </>
                  )}
                </div>
              </div>

              {/* Card Content */}
              <div className="px-6 py-4">
                <div className="flex items-start space-x-3">
                  <ChatBubbleLeftRightIcon className="h-5 w-5 text-gray-400 mt-0.5" />
                  <p className="text-sm text-gray-600 line-clamp-2">
                    {agent.welcomeMessage}
                  </p>
                </div>
              </div>

              {/* Card Footer */}
              <div className="px-6 py-3 bg-gray-50 group-hover:bg-gray-100/80 transition-colors duration-300">
                <div className="flex items-center justify-between text-xs text-gray-500">
                  <div className="flex items-center">
                    <CalendarIcon className="h-4 w-4 mr-1.5" />
                    <span>Created {formatDate(agent.createdAt)}</span>
                  </div>
                  <div className="flex items-center">
                    <CalendarIcon className="h-4 w-4 mr-1.5" />
                    <span>Updated {formatDate(agent.updatedAt)}</span>
                  </div>
                </div>
              </div>

              {/* Status Overlay */}
              {isAgentBeingCreated(agent) && status && (
                <div className="absolute inset-0 flex items-center justify-center bg-white/50">
                  <div className="flex flex-col items-center space-y-2">
                    <ArrowPathIcon className="h-8 w-8 text-uehPrimary animate-spin" />
                    <div className="text-sm font-medium text-gray-700">
                      {status.status}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {selectedAgent && (
        <EditAgentModal
          isOpen={isEditModalOpen}
          agent={selectedAgent}
          onClose={handleCloseEditModal}
          onAgentUpdated={handleAgentUpdated}
        />
      )}
    </div>
  );
};

export default AgentList;
