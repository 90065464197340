import { ChatBubbleBottomCenterTextIcon, ChevronDownIcon, CircleStackIcon, DocumentIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import blueUEHLogo from '../assets/images/logo/logo-ueh/logo-ueh-xanh.png';
import Toolbar from './Toolbar';
import { useQuery } from '@apollo/client';
import { GetUserInfoQuery, GetUserInfoQueryVariables } from '@/gql/graphql';
import { GET_USER_INFO } from '@/graphql/queries';
import { useKeycloakAuth } from '@/context/KeycloakAuthProvider';

const RagHeader = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const [openAccordionId, setOpenAccordionId] = useState<string | null>(null);
  const [isAdminUser, setIsAdminUser] = useState(false);

  const { user, isAdmin } = useKeycloakAuth();
  const { data } = useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GET_USER_INFO, {
    variables: { id: user?.id },
    skip: !user?.id
  });

  const userInfo = data?.findFirstUser;

  useEffect(() => {
    const checkAdmin = async () => {
      const adminStatus = await isAdmin();
      setIsAdminUser(adminStatus);
    };
    checkAdmin();
  }, [isAdmin]);

  const tagsData = useMemo(
    () => {
      const baseRoutes = [
        { path: '/chat', name: 'Chat', icon: ChatBubbleBottomCenterTextIcon },
      ];

      const adminRoutes = [
        { path: '/agents', name: 'Agents', icon: UserCircleIcon },
        { path: '/file', name: 'File Manager', icon: DocumentIcon }
      ];

      return isAdminUser ? [...baseRoutes, ...adminRoutes] : baseRoutes;
    },
    [isAdminUser]
  );

  const currentPath = useMemo(() => {
    return tagsData.find((x) => pathname.startsWith(x.path))?.name || 'Chat';
  }, [pathname, tagsData]);

  const handleChange = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  useEffect(() => {
    if (pathname === '/') {
      handleChange('/chat');
    }
  }, [handleChange, pathname]);

  const handleLogoClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div className='flex h-screen overflow-hidden'>
      <div className='relative flex flex-1 flex-col overflow-hidden'>
        <header className='h-18 flex items-center justify-between bg-white px-4 shadow'>
          <div className='flex cursor-pointer items-center space-x-3' onClick={handleLogoClick}>
            <img src={blueUEHLogo} alt='App Logo' className='h-10 p-2' />
            <span className='text-md hidden py-2 font-iciel font-bold text-uehPrimary md:block'>UEH Chatbot</span>
          </div>
          {tagsData.length > 1 && (
            <>
              <div className='m-2 hidden space-x-4 bg-slate-50 font-bold md:flex'>
                {tagsData.map((item) => (
                  <button
                    key={item.name}
                    className={`group flex items-center space-x-2 rounded px-3 py-2 ${
                      currentPath === item.name ? 'bg-uehPrimary text-white' : 'hover:duration-400 hover:text-uehSecondary hover:transition-colors'
                    }`}
                    onClick={() => handleChange(item.path)}>
                    <item.icon
                      className={`group-hover:duration-400 h-5 w-5 font-myriad text-uehPrimary group-hover:text-uehSecondary group-hover:transition-colors ${
                        currentPath === item.name ? 'text-white' : ''
                      }`}
                    />
                    <span>{item.name}</span>
                  </button>
                ))}
              </div>
              <div className='relative z-50 w-40 md:hidden'>
                <div
                  className='m-2 flex w-40 cursor-pointer items-center justify-center space-x-2 rounded bg-slate-50 px-3 py-2 font-bold'
                  onClick={() => setOpenAccordionId(openAccordionId ? null : 'mobileMenu')}>
                  <span>{currentPath}</span>
                  <ChevronDownIcon className={`h-5 w-5 transition-transform ${openAccordionId === 'mobileMenu' ? 'rotate-180 transform' : ''}`} />
                </div>
                {openAccordionId === 'mobileMenu' && (
                  <div className='absolute left-0 top-full w-full rounded border bg-white shadow-lg hover:bg-gray-100'>
                    {tagsData.map((item) => (
                      <button
                        key={item.name}
                        className={`flex items-center space-x-2 truncate px-4 py-2 text-left ${currentPath === item.name ? 'w-full bg-gray-200' : ''}`}
                        onClick={() => {
                          handleChange(item.path);
                          setOpenAccordionId(null);
                        }}>
                        <item.icon className={`h-5 w-5 ${currentPath === item.name ? 'text-uehPrimary' : 'text-gray-500'}`} />
                        <span className='font-myriad'>{item.name}</span>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
          <Toolbar 
            userName={userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : ''} 
            firstLetter={userInfo ? (userInfo.firstName?.[0] || '').toUpperCase() : ''} 
          />
        </header>
        <Outlet />
      </div>
    </div>
  );
};

export default RagHeader;
