import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GetAgentsQuery, Agent } from '@/gql/graphql';
import { GetAgentsDocument } from '@/gql/graphql';
import AgentList from '@/components/agent/AgentList';
import { useApi } from '@/context/ApiContext';

interface TaskStatus {
  state: string;
  status: string;
}

const AgentsPage = () => {
  const { loading, error, data, refetch } = useQuery<GetAgentsQuery>(GetAgentsDocument);
  const [agentsInProgress, setAgentsInProgress] = useState<Set<string>>(new Set());
  const [agentStatuses, setAgentStatuses] = useState<Record<string, TaskStatus>>({});

  useEffect(() => {
    if (data?.findManyAgents) {
      // Find agents that are not in COMPLETED or ERROR state
      const inProgressAgents = data.findManyAgents.filter(
        agent => agent.status !== 'COMPLETED' && agent.status !== 'ERROR'
      );
      
      setAgentsInProgress(new Set(inProgressAgents.map(agent => agent.id)));
    }
  }, [data]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (agentsInProgress.size > 0) {
      intervalId = setInterval(async () => {
        // Fetch fresh data
        const { data: freshData } = await refetch();
        const newStatuses: Record<string, TaskStatus> = {};
        let allCompleted = true;

        for (const agentId of agentsInProgress) {
          const agent = freshData?.findManyAgents.find(a => a.id === agentId);
          if (agent) {
            newStatuses[agentId] = {
              state: agent.status,
              status: agent.status
            };

            if (agent.status !== 'COMPLETED' && agent.status !== 'ERROR') {
              allCompleted = false;
            }
          }
        }

        setAgentStatuses(newStatuses);

        if (allCompleted) {
          clearInterval(intervalId);
          setAgentsInProgress(new Set());
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [agentsInProgress, refetch]);

  const isAgentBeingCreated = (agent: Agent): boolean => {
    return agentsInProgress.has(agent.id) && agent.status !== 'COMPLETED' && agent.status !== 'ERROR';
  };

  const getAgentStatus = (agent: Agent) => {
    return agentStatuses[agent.id] || null;
  };

  return (
    <div className='container mx-auto px-4 py-8'>
      <div className='mb-6 flex items-center justify-between'>
        <h1 className='text-2xl font-bold text-gray-900'>Agents</h1>
      </div>

      <AgentList
        agents={data?.findManyAgents || []}
        onAgentUpdated={refetch}
        isAgentBeingCreated={isAgentBeingCreated}
        getAgentStatus={getAgentStatus}
      />
    </div>
  );
};

export default AgentsPage;
